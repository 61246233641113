import { FC, Fragment } from 'react';
import { navigate } from 'gatsby';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { SearchBox, WithSearch } from '@elastic/react-search-ui';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from '../../hooks/useTranslation';
import { usePromotionContext } from '../../providers/PromotionProvider';
import PGEButton from '../buttons/PGE-Button';
import PGELogo from '../logos/PGELogo';
import ROUTES from '../../routes';
import { NavigationUser } from './NavigationUser';
import { NavigationMegaMenu } from './NavigationMegaMenu';
import MobileHeader from '../mobile-header';
import { isMobile } from '../../util/style-utils';
import colors from '../../themes/main-colors';
import useAuth from '../../hooks/useAuth';
import { ColorName } from '../../constants';
import TEST_IDS from '../../constants/test_ids';
import { useTheme } from '@mui/material';
import { css } from '@emotion/react';

export const Navigation: FC = () => {
  const theme = useTheme()
  const { t } = useTranslation();
  const language = 'en';
  const { signOut, isAuthenticated } = useAuth();
  const { promotion, noLogo, noSignIn } = usePromotionContext();

  const isBrowser = !(typeof window === 'undefined');
  const isOutages = isBrowser && window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES;
  const isMobileView = isMobile();

  if (isMobileView || isOutages) {
    return (
      <MobileHeader title={isOutages && isMobileView ? t('OUTAGE_MAP') : ''} />
    );
  }

  return (
    <div data-swiftype-index="false" css={css`
      html {
        scroll-behavior: smooth;
      }

      .search {
        padding: ${theme.spacing(0, 2.5)};
        display: inline-flex;
        vertical-align: bottom;
      }

      .promotionPage {
        background: ${ColorName.Grey};
      }

      .spaceholder {
        display: flex;
        width: 50%;
      }
    `}>
      {!promotion && (
        <div data-testid={TEST_IDS.APP_BAR} css={css`
          padding: 5px 0;
          height: 200px;
          width: 100%;
          max-height: 85px;
          background: linear-gradient(48deg, #0096a2 40%, #0064a2 90%);

          & .MuiContainer-root {
            display: flex;
            align-items: center;
            padding-left: 12px;
          }

          & .user-logged-in {
            display: flex;
            align-items: center;
            padding-left: 12px;
          }

          & .user-logged-in .item {
            flex: auto;
            margin-right: 20px;
          }
          & .user-logged-in .item.promotion {
            color: #006DBA;
          }

          & .user-logged-in .item a {
            color: #fff;
            text-decoration: none;
          }
        `}>
          <Container>
            <div css={css`
              flex: 3 1;
              width: 50%;
            `}>
              {isBrowser && (
                <IconButton
                  edge="start"
                  aria-label={t('NAVIGATE_HOME')}
                  onClick={() => navigate('/')}
                >
                  <PGELogo
                    css={css`
                      width: 50px;
                      height: 50px;

                      /*TODO: does this do anything?*/
                      & .letters {
                        fill: white;
                      }
                      /*TODO: does this do anything?*/
                      & .angles {
                        fill: white;
                      }
                    `}
                    color="#fff"
                    viewBox="0 0 288 288"
                  />
                </IconButton>
              )}
            </div>

            <div css={css`
              flex: 3 1;
              width: 50%;
              flex: 1 1;
              color: #fff;
              text-align: right;

              display: flex;
              flex: 2 !important;
              justify-content: flex-end;

              & > form {
                flex: 1;
                width: 100%;
              }
              & .sui-search-box__wrapper {
                /* width: 300px; */
              }
              & .sui-search-box {
                padding: ${theme.spacing(0, 1.25, 0, 0)};
              }
              & .sui-search-box__submit {
                display: none;
              }
              & .sui-search-box__text-input {
                padding: ${theme.spacing(1)};
              }
              & .sui-search-box__autocomplete-container li {
                text-align: left;
              }
              & .sui-search-box__section-title {
                text-align: left;
              }
              & .sui-search-box__autocomplete-container {
                top: 99%;
              }

              & .MuiSvgIcon-root {
                margin: ${theme.spacing(1.5, 0, 0, -5)};
                z-index: 1;
                fill: ${colors.noirBlur};
                height: ${theme.spacing(2)};
              }
              & .MuiButton-outlinedPrimary {
                margin-left: ${theme.spacing(2)};
              }
            `}>
              {isBrowser && (
                <WithSearch
                  mapContextToProps={({
                    searchTerm,
                    setSearchTerm,
                    results,
                  }: any) => ({
                    searchTerm,
                    setSearchTerm,
                    results,
                  })}
                >
                  {({ searchTerm, setSearchTerm, results }: any) => {
                    return (
                      <Fragment>
                        <SearchBox
                          autocompleteMinimumCharacters={3}
                          autocompleteResults={{
                            sectionTitle: 'Results',
                            titleField: 'title',
                            urlField: 'url',
                            shouldTrackClickThrough: true,
                            clickThroughTags: ['test'],
                          }}
                          autocompleteSuggestions={true}
                          debounceLength={0}
                          onSubmit={async (value: any) => {
                            await navigate('/search?q=' + value);
                            setSearchTerm(value);
                          }}
                        />
                        <SearchIcon
                          style={{ cursor: 'pointer' }}
                          onClick={async (e: any) => {
                            await navigate('/search?q=' + searchTerm);
                            setSearchTerm(searchTerm);
                          }}
                        />
                      </Fragment>
                    );
                  }}
                </WithSearch>
              )}

              {!isAuthenticated && isBrowser && (
                <Fragment>
                  <PGEButton
                    data-testid={'sign-in-route-button'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={async (e: any) => {
                      e.preventDefault();
                      await navigate(ROUTES.SIGN_IN);
                    }}
                    href={ROUTES.SIGN_IN}
                  >
                    {t('SIGN_IN')}
                  </PGEButton>
                  <PGEButton
                    data-testid={'sign-in-route-button'}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={async (e: any) => {
                      e.preventDefault();
                      await navigate(ROUTES.REGISTRATION);
                    }}
                    css={css`
                      margin-left: ${theme.spacing(1)};
                    `}
                    href={ROUTES.REGISTRATION}
                  >
                    {t('REGISTER')}
                  </PGEButton>
                </Fragment>
              )}
              {isAuthenticated && isBrowser && (
                <NavigationUser
                  signOut={signOut}
                  language={language}
                  //handleLanguageChange={handleLanguageChange}
                />
              )}
            </div>
          </Container>
        </div>
      )}

      {promotion && (
        <div css={css`
          padding: 5px 0;
          height: 200px;
          width: 100%;
          max-height: 85px;
          /* background: linear-gradient(48deg, #0096a2 40%, #0064a2 90%); */
          background: ${ColorName.Grey};
        `}>
          <Container>
            {!noLogo && (
              <div css={css`
                flex: 3 1;
                width: 50%;
              `}>
                <IconButton
                  edge="start"
                  aria-label={t('NAVIGATE_HOME')}
                  onClick={() => navigate('/')}
                  size="large">
                  <PGELogo
                    css={css`
                      width: 50px;
                      height: 50px;

                      /*TODO: does this do anything?*/
                      & .letters {
                        fill: white;
                      }
                      /*TODO: does this do anything?*/
                      & .angles {
                        fill: white;
                      }
                    `}
                    color="#006DBA"
                    viewBox="0 0 288 288"
                  />
                </IconButton>
              </div>
            )}
            <div css={css`
              flex: 3 1;
              width: 50%;
              flex: 1 1;
              color: #fff;
              text-align: right;
            `}>
              {!isAuthenticated && !noSignIn && (
                <PGEButton
                  data-testid={'sign-in-route-button'}
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={async (e: any) => {
                    e.preventDefault();
                    await navigate(ROUTES.SIGN_IN);
                  }}
                  href={ROUTES.SIGN_IN}
                >
                  {t('SIGN_IN')}
                </PGEButton>
              )}
              {isAuthenticated && !noSignIn && (
                <NavigationUser
                  signOut={signOut}
                  language={language}
                  //handleLanguageChange={handleLanguageChange}
                />
              )}
            </div>
          </Container>
        </div>
      )}
      {!promotion && isBrowser && (
        <NavigationMegaMenu isAuthenticated={isAuthenticated} />
      )}
    </div>
  );
};
